import axios from "axios";
const local = {
  createAxiosInstance() {
    local.apiInstance = axios.create({
      baseURL: process.env.VUE_APP_BASE_URL,
      headers: { Accept: "application/json" },
    });
  },
};
const authenticate = {
  getHorarios() {
    return new Promise((resolve, reject) => {
      local.apiInstance
        .get("/horarios")
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getBitacora(){
    return new Promise((resolve, reject) => {
      local.apiInstance
        .get("/bitacora")
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  guardarInformacion(payload){
    return new Promise((resolve, reject) => {
        local.apiInstance
          .post("/insertar-cita",payload)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
  }
};
// self invoking function
(() => {
  local.createAxiosInstance();
})();
export default authenticate;
