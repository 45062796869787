<template>
<v-app>
    <div v-if="spin" id="efecto_carga">
        <div class="loader3"></div>
    </div>
    <div id="image" style="width: 300px; height: 300px;">

        <v-img :src="require('@/assets/pandora.png')" class="my-3" />
    </div>
    <v-main>
        <h1 style="text-align:center;">Agenda Tu Cita</h1>
        <v-data-table @click:row="showModal" :item-class="rowClass" hide-default-footer :headers="headers" :items="items">
            <template v-slot:[`item.habilitado`]="props">
                {{ props.item.habilitado? 'Disponible': 'Ocupado' }}
            </template>
            <template v-slot:[`item.empresa`]="props">
                {{ props.item.empresa? props.item.empresa: 'Sin Registro' }}
            </template>
        </v-data-table>
        <v-dialog v-model="dialog" width="600">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    Insertar datos de contacto
                </v-card-title>

                <v-card-text>
                    <v-form v-model="valid">
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="6" sm="12" lg="6">
                                    <v-text-field v-model="name" :rules="nameRules" label="Nombre" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6" sm="12" lg="6">
                                    <v-text-field v-model="documento" :rules="documentoRules" label="Documento" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6" sm="12" lg="6">
                                    <v-text-field v-model="email" :rules="emailRules" label="E-mail" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6" sm="12" lg="6">
                                    <v-text-field v-model="celular" :rules="celularRules" label="Celular" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6" sm="12" lg="6">
                                    <v-text-field v-model="empresa" :rules="empresaRules" label="Empresa" required></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" text @click="cancel(); dialog = false;">
                        Cancelar
                    </v-btn>
                    <v-btn color="success" text @click="submit">
                        Insertar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-main>

</v-app>
</template>

<script>
import services from "@/services";
export default {
    name: 'App',

    components: {},
    created() {
        this.spin = true;
        services.getHorarios().then((response) => {
            console.log(response);
            this.items = response;
            this.spin = false;
        }).catch((error) => {
            console.log(error);
        });
    },
    data: () => ({
        headers: [{
                text: 'Habilitado',
                align: 'start',
                sortable: true,
                value: 'habilitado',
            },
            {
                text: 'Hora Inicial',
                align: 'start',
                value: 'hora_inicial',
            },
            {
                text: 'Empresa',
                align: 'start',
                value: 'empresa',
            },
        ],
        items: [],
        dialog: false,
        valid: false,
        name: '',
        nameRules: [
            v => !!v || 'Nombre es requerido',
        ],
        email: '',
        emailRules: [
            v => !!v || 'Correo es requerido',
            v => /.+@.+/.test(v) || 'Debe ser un correo valido',
        ],
        celular: '',
        celularRules: [
            v => !!v || 'Celular es requerido',
            v => !isNaN(v) || 'Debe ser un número valido'
        ],
        empresa: '',
        empresaRules: [
            v => !!v || 'Empresa es requerido',
        ],
        documento: '',
        documentoRules: [
            v => !!v || 'Documento es requerido',
            v => !isNaN(v) || 'Debe ser un documento valido'
        ],
        tempItem: null,
        spin: false,

    }),
    methods: {
        rowClass(item) {
            return item.habilitado ? '' : 'used';
        },
        showModal(item) {
            if (item.habilitado) {
                this.tempItem = item;
                this.dialog = true;
            }
        },
        cancel() {
            this.name = '';
            this.email = '';
            this.celular = '';
            this.empresa = '';
            this.documento = '';
        },
        submit() {
            if (this.valid) {
                this.$swal.fire({
                    title: 'Quieres registrar la información brindada?',
                    showDenyButton: true,
                    showCancelButton: true,
                    confirmButtonText: 'Si',
                    denyButtonText: `No`,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        this.spin = true;
                        services.guardarInformacion({
                            'cedula': this.documento,
                            'id_horario': this.tempItem.id,
                            'empresa': this.empresa,
                            'telefono': this.celular,
                            'nombre': this.name,
                        }).then(() => {
                            this.tempItem.empresa = this.empresa;
                            this.$swal.fire('Información Guardada', '', 'sucess');
                            this.dialog = false;
                            this.tempItem.habilitado = false;
                            this.spin = false;
                        }).catch((error) => {
                            this.spin = false;
                            switch (error.response.status) {
                                case 422:
                                    this.$swal.fire('La información brindada es incorrecta, verifique e intente nuevamente', '', 'error');
                                    break;
                                case 400:
                                    this.$swal.fire('El horario escogido ha sido tomado previamente', '', 'error');
                                    this.tempItem.habilitado = false;
                                    this.dialog = false;
                                    this.tempItem.empresa = error.response.data.empresa;
                                    break;

                                default:
                                    break;
                            }

                        }).finally(() => {
                            this.cancel();
                        });
                    } else if (result.isDenied) {
                        this.$swal.fire('Guardado Abortado', '', 'sucess');
                    }
                })

            }
        }
    }
};
</script>

<style>
.used {
    background: rgb(0 0 0 / 14%);
}

@media (max-width: 800px) {
    #image {
        margin: auto;
    }
}

#efecto_carga {
    background-color: rgb(250 240 245 / 73%);
    height: 100%;
    width: 100%;
    position: fixed;
    transition: all 1s ease;
    z-index: 1000;
}

.carga {
    border: 15px solid #83a57e7a;
    border-top-color: #19b578db;
    border-top-style: groove;
    height: 100px;
    width: 100px;
    border-radius: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    animation: girar 1.5s linear infinite;
}

@keyframes girar {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

/*------------------------------------------------------------------------------------------------*/
.loader,
.loader:before,
.loader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
}

.loader {
    color: #19b578db;
    font-size: 10px;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

.loader:before,
.loader:after {
    content: "";
    position: absolute;
    top: 0;
}

.loader:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.loader:after {
    left: 3.5em;
}

@-webkit-keyframes load7 {

    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }

    40% {
        box-shadow: 0 2.5em 0 0;
    }
}

@keyframes load7 {

    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }

    40% {
        box-shadow: 0 2.5em 0 0;
    }
}

/*------------------------------------------------------------------------------------------------*/
.loader1 {
    margin: auto;
    font-size: 25px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    text-indent: -9999em;
    -webkit-animation: load5 1.1s infinite ease;
    animation: load5 1.1s infinite ease;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

@keyframes load5 {

    0%,
    100% {
        box-shadow: 0em -2.6em 0em 0em #19b578db, 1.8em -1.8em 0 0em rgb(35 185 118 / 20%),
            2.5em 0em 0 0em rgb(35 185 118 / 20%), 1.75em 1.75em 0 0em rgb(35 185 118 / 20%),
            0em 2.5em 0 0em rgb(35 185 118 / 20%), -1.8em 1.8em 0 0em rgb(35 185 118 / 20%),
            -2.6em 0em 0 0em rgb(107 193 114 / 50%), -1.8em -1.8em 0 0em rgb(40 134 52 / 70%);
    }

    12.5% {
        box-shadow: 0em -2.6em 0em 0em rgb(40 134 52 / 70%), 1.8em -1.8em 0 0em #19b578db,
            2.5em 0em 0 0em rgb(35 185 118 / 20%), 1.75em 1.75em 0 0em rgb(35 185 118 / 20%),
            0em 2.5em 0 0em rgb(35 185 118 / 20%), -1.8em 1.8em 0 0em rgb(35 185 118 / 20%),
            -2.6em 0em 0 0em rgb(35 185 118 / 20%), -1.8em -1.8em 0 0em rgb(107 193 114 / 50%);
    }

    25% {
        box-shadow: 0em -2.6em 0em 0em rgb(107 193 114 / 50%),
            1.8em -1.8em 0 0em rgb(40 134 52 / 70%), 2.5em 0em 0 0em #19b578db,
            1.75em 1.75em 0 0em rgb(35 185 118 / 20%), 0em 2.5em 0 0em rgb(35 185 118 / 20%),
            -1.8em 1.8em 0 0em rgb(35 185 118 / 20%), -2.6em 0em 0 0em rgb(35 185 118 / 20%),
            -1.8em -1.8em 0 0em rgb(35 185 118 / 20%);
    }

    37.5% {
        box-shadow: 0em -2.6em 0em 0em rgb(35 185 118 / 20%),
            1.8em -1.8em 0 0em rgb(107 193 114 / 50%), 2.5em 0em 0 0em rgb(40 134 52 / 70%),
            1.75em 1.75em 0 0em #19b578db, 0em 2.5em 0 0em rgb(35 185 118 / 20%),
            -1.8em 1.8em 0 0em rgb(35 185 118 / 20%), -2.6em 0em 0 0em rgb(35 185 118 / 20%),
            -1.8em -1.8em 0 0em rgb(35 185 118 / 20%);
    }

    50% {
        box-shadow: 0em -2.6em 0em 0em rgb(35 185 118 / 20%),
            1.8em -1.8em 0 0em rgb(35 185 118 / 20%), 2.5em 0em 0 0em rgb(107 193 114 / 50%),
            1.75em 1.75em 0 0em rgb(40 134 52 / 70%), 0em 2.5em 0 0em #19b578db,
            -1.8em 1.8em 0 0em rgb(35 185 118 / 20%), -2.6em 0em 0 0em rgb(35 185 118 / 20%),
            -1.8em -1.8em 0 0em rgb(35 185 118 / 20%);
    }

    62.5% {
        box-shadow: 0em -2.6em 0em 0em rgb(54 140 75 / 20%),
            1.8em -1.8em 0 0em rgb(54 140 75 / 20%), 2.5em 0em 0 0em rgb(54 140 75 / 20%),
            1.75em 1.75em 0 0em rgb(107 193 114 / 50%), 0em 2.5em 0 0em rgb(54 140 75 / 70%),
            -1.8em 1.8em 0 0em #19b578db, -2.6em 0em 0 0em rgb(54 140 75 / 20%),
            -1.8em -1.8em 0 0em rgb(54 140 75 / 20%);
    }

    75% {
        box-shadow: 0em -2.6em 0em 0em rgb(54 140 75 / 20%),
            1.8em -1.8em 0 0em rgb(54 140 75 / 20%), 2.5em 0em 0 0em rgb(54 140 75 / 20%),
            1.75em 1.75em 0 0em rgb(54 140 75 / 20%), 0em 2.5em 0 0em rgb(107 193 114 / 50%),
            -1.8em 1.8em 0 0em rgb(54 140 75 / 70%), -2.6em 0em 0 0em #19b578db,
            -1.8em -1.8em 0 0em rgb(54 140 75 / 20%);
    }

    87.5% {
        box-shadow: 0em -2.6em 0em 0em rgb(54 140 75 / 20%),
            1.8em -1.8em 0 0em rgb(54 140 75 / 20%), 2.5em 0em 0 0em rgb(54 140 75 / 20%),
            1.75em 1.75em 0 0em rgb(54 140 75 / 20%), 0em 2.5em 0 0em rgb(54 140 75 / 20%),
            -1.8em 1.8em 0 0em rgb(107 193 114 / 50%), -2.6em 0em 0 0em rgb(54 140 75 / 70%),
            -1.8em -1.8em 0 0em #19b578db;
    }
}

/*------------------------------------------------------------------------------------------------*/

.loader2 {
    color: #19b578db;
    font-size: 20px;
    margin: auto;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-indent: -9999em;
    animation: load4 1.3s infinite linear;
    transform: translateZ(0);
}

@keyframes load4 {

    0%,
    100% {
        box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
            -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }

    12.5% {
        box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em,
            -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    25% {
        box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em,
            -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    37.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em,
            -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    50% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em,
            -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    62.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0,
            -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }

    75% {
        box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
            0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }

    87.5% {
        box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
            -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
}

.loader3 {
    color: #19b578db;
    font-size: 90px;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateZ(0);
    animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}

@keyframes load6 {
    0% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
            0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }

    5%,
    95% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
            0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }

    10%,
    59% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
            -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
            -0.297em -0.775em 0 -0.477em;
    }

    20% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
            -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
            -0.749em -0.34em 0 -0.477em;
    }

    38% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
            -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }

    100% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
            0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
}

@keyframes round {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loader4,
.loader4:before,
.loader4::after {
    background: #189967;
    animation: load1 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
    border-radius: 50%;
}

.loader4 {
    color: #189967;
    text-indent: -9999em;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 11px;
    transform: translateZ(0);
    animation-delay: -0.16s;
}

.loader4:before,
.loader4:after {
    position: absolute;
    top: 0;
    content: "";
}

.loader4:before {
    left: -1.5em;
    animation-delay: -0.32s;
}

.loader4:after {
    left: 1.5em;
}

@keyframes load1 {

    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }

    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}
</style>
